body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.Polaris-DatePicker__MonthLayout {
  margin-top: 0px;
}
.date-picker-btn-group {
  display: flex;
  justify-content: flex-end;
}
.Polaris-PositionedOverlay {
  z-index: 510;
}
.flex-end {
  justify-content: flex-end;
}
.mt-auto {
  margin-top: auto;
}
.ml-8 {
  margin-left: 8px;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-8 {
  margin-top: 8px;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 0.25rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.mt-4 {
  margin-top: 4px;
}
.my-16 {
  margin: 1rem 0;
}
.p-8 {
  padding: 0.5rem;
}
.p-16 {
  padding: 1rem;
}
.pd-20 {
  padding: 20px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pd-8 {
  padding: 0.5rem;
}
.pl-16 {
  padding-left: 1rem;
}
.ml-4 {
  margin-left: 4px;
}

.width-100 {
  width: 100%;
}
.width-30 {
  width: 30%;
}
.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}
.width-70 {
  width: 70%;
}

.height-full {
  height: 100%;
}

.link-no-monochrome .Polaris-Link {
  color: rgba(0, 91, 211, 1);
}
select {
  padding: 4px;
}
.break-word {
  overflow-wrap: break-word;
  word-break: break-all;
}
.text-align {
  text-align: center;
}
.Polaris-IndexTable__TableRow {
  cursor: auto;
}
.pointer {
  cursor: pointer;
}
.pointer-events-none {
  pointer-events: none;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.Polaris-Pagination {
  display: flex;
  justify-content: center;
}
